@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  font-family: "Roboto", sans-serif;
}

* {
  outline: none !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*::-webkit-scrollbar {
  
  height: 2px;
  width: 2px;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: transparent !important;
  height: 70%;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent !important;
}

*::-webkit-scrollbar-track:active {
  background-color: transparent !important;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ddcb9b;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #fcc586;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  animation: bg-pan-right 10s infinite alternate forwards;
}

@keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 0px solid transparent !important;
  outline-offset: 0px !important;
  --tw-ring-inset: transparent !important;
  --tw-ring-offset-width: transparent !important;
  --tw-ring-offset-color: transparent !important;
  --tw-ring-color: transparent !important;
  --tw-ring-offset-shadow: transparent !important;
  --tw-ring-shadow: transparent !important;
  box-shadow: transparent !important;
  border-color: transparent !important;
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border-bottom: 2px solid #9ca3af !important;
  border-color: #9ca3af !important;
}